import React, { FC, useEffect } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Box, Typography, AppBar, Toolbar, fade } from '@material-ui/core';
import { GRADIENTS } from '../../Theme/themeValues';
import Typo from '../../Components/Typo';
import NavBar from './NavBar';
import { APP_HEADER_HEIGHT } from '../../Constants';
import { Link } from 'react-router-dom';
import LOGO from '../../Assets/images/Logo.png';
import gsap from 'gsap';
import { useMediaQuery } from '../../Hooks/useMediaQuery';
import NavBarMobile from './NavBarMobile';

export interface AppHeaderProps {

}

const AppHeader: FC<AppHeaderProps> = (props) => {
    const classes = useStyles();
    const { isDeviceSm } = useMediaQuery();

    useEffect(() => {
        gsap.timeline()
            .to(`.${classes.root}`, {
                y: 0,
                duration: 0.5,
                delay: 1,
            })

    }, [])

    return (
        <AppBar className={classes.root} elevation={1}>
            <Toolbar>
                <Link to="/">
                    <img src={LOGO} alt="venzi-logo" width="120px" />
                </Link>
                <Box flex={1} />
                {isDeviceSm ? <NavBarMobile /> : <NavBar />}
            </Toolbar>
        </AppBar>
    )
}


const useStyles = makeStyles<Theme, any>((theme) => ({
    root: {
        background: fade('#f7f7f7', 0.9),
        // backgroundColor: 'transparent',
        backdropFilter: 'blur(10px)',

        height: APP_HEADER_HEIGHT,
        position: 'sticky',
        top: 0,
        transform: `translateY(-${APP_HEADER_HEIGHT}px)`
    }
}));

export default AppHeader;

