import React, { FC, useEffect, useMemo } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import config from './config.json';
import Slider, { Settings } from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export interface AnimatedBackgroundProps {

}


const getAssetUrl = (asset: string) => {
    try {
        return require('./assets/' + asset)
    } catch (error) {
        return ''
    }
}


const AnimatedBackground: FC<AnimatedBackgroundProps> = (props) => {
    const classes = useStyles(config.overlay);

    const settings: Settings = {
        dots: false,
        autoplaySpeed: config.duration,
        fade: true,
        autoplay: true,
        infinite: true,
        arrows: false,
        speed: config.transitionSpeed,
        slidesToShow: 1,
        slidesToScroll: 1,
    }

    const assets = useMemo(() => config.assets, [])



    return (
        <div className={classes.root} >
            <div className={classes.overlay} />
            <Slider className={classes.root} {...settings}>
                {
                    assets.map((it, i) => {
                        return (
                            <img className={classes.image} src={getAssetUrl(it)} key={i} width="100%" alt='event-image' />
                        )
                    })
                }
            </Slider>
        </div>
    )
}

const useStyles = makeStyles<Theme, { color: string, opacity: number }>((theme) => ({
    root: {
        width: '100%',
        height: '100%',
    },
    image: {
        zIndex: 0,
        [theme.breakpoints.down('sm')]: {
            height: '100vh',
            objectFit: 'cover'
        }
    },
    overlay: {
        position: 'fixed',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 1,
        backgroundColor: props => props.color,
        opacity: props => props.opacity
    }
}));

export default AnimatedBackground;