import React, { FC, useContext, useEffect, useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Box, IconButton } from '@material-ui/core';
import DiscNavigator from '../../Components/DiscNavigator';
import { NavigatorWheelContext } from '../../Contexts/NavigatorWheelContext';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';
import gsap from 'gsap';
import { APP_HEADER_HEIGHT, CIRCULAR_NAV_RADIUS, VENZI_WHEEL_LABELS } from '../../Constants';
import { getSectionId } from '.';
import { useHistory } from 'react-router-dom';

export interface DiscMobileProps {

}

const DiscMobile: FC<DiscMobileProps> = (props) => {
    const classes = useStyles({});
    const { curSection, setCurSection } = useContext(NavigatorWheelContext);
    const [expanded, setExpanded] = useState(false);
    const history = useHistory();

    const ID = 'DISC-FEATURE-MOBILE';
    const duration = gsap.utils.mapRange(0, VENZI_WHEEL_LABELS.length - 1, 0.3, 2.5)

    useEffect(() => {
        collapse();
    }, [])

    const expand = () => {
        setExpanded(true);
        gsap.to(`#${ID}`, {
            x: -CIRCULAR_NAV_RADIUS,
        })
    }
    const collapse = () => {
        setExpanded(false);
        gsap.to(`#${ID}`, {
            x: -(CIRCULAR_NAV_RADIUS * 2) + 20
        })
    }

    const handleSectionChange = (index: number) => {
        setCurSection(index);
        history.replace('/features#' + getSectionId(index))
        scrollWindow(curSection, index);
        setTimeout(() => {
            collapse()
        }, duration(Math.abs(curSection - index)) * 1000);

    }

    const scrollWindow = (prevIndex: number, curIndex: number) => {
        setTimeout(() => {
            gsap.to(window, {
                scrollTo: {
                    y: `#${getSectionId(curIndex)}`,
                    offsetY: APP_HEADER_HEIGHT,
                },
                // delay: 1,
                duration: duration(Math.abs(prevIndex - curIndex)),
                ease: "power2.easeOut"
            })
        }, 0);

    }



    return (
        <Box>
            {expanded ? <IconButton className={classes.expandIcon} onClick={collapse}><ChevronLeft /></IconButton> : <IconButton className={classes.expandIcon} onClick={expand}><ChevronRight /></IconButton>}
            <div id={ID} className={classes.disc}>
                <DiscNavigator currentSection={curSection} onSectionChanged={handleSectionChange} />
            </div>
        </Box>
    )
    // <IconButton onClick={expand}><ChevronRight /></IconButton>
}

const useStyles = makeStyles<Theme, any>((theme) => ({
    disc: {
        position: 'fixed',
        top: APP_HEADER_HEIGHT + 60,
        zIndex: 10
    },
    expandIcon: {
        position: 'fixed',
        left: 0,
        zIndex: 1,
        top: APP_HEADER_HEIGHT
    }
}));

export default DiscMobile;