import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { MuiThemeProvider } from '@material-ui/core/styles';
import Theme from './Theme';
import RootLayout from './Screens/RootLayout';
import Boot from './Boot';
import ContextProviders from './Contexts';
import gsap from 'gsap';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { TextPlugin } from 'gsap/TextPlugin'

gsap.registerPlugin(ScrollToPlugin, ScrollTrigger, TextPlugin);




const App: React.FC = () => {

  Boot().then(() => {

  });
  return (
    <BrowserRouter>
      <MuiThemeProvider theme={Theme}>
        <ContextProviders>
          <RootLayout />
        </ContextProviders>
      </MuiThemeProvider>
    </BrowserRouter>
  );
}

export default App;
