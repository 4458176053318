import React, { FC, useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Box, Button, IconButton, Portal, Toolbar } from '@material-ui/core';
import useDrawer from '../../Components/useDrawer';
import { CloseOutlined } from '@material-ui/icons';
import { useNavigation } from './useNavigation';
import { Link, NavLink } from 'react-router-dom';
import clsx from 'clsx';
import RoundedButton from '../../Components/RoundedButton';
import { APP_HEADER_HEIGHT } from '../../Constants';

export interface NavBarMobileProps {

}

const NavBarMobile: FC<NavBarMobileProps> = (props) => {
    const classes = useStyles({});

    const { UiComponent: Drawer, open } = useDrawer({
        renderChild: (closeDrawer) => <DrawerComponent closeDrawer={closeDrawer} />,
        PaperProps: { className: classes.drawer }
    })

    return (
        <Box>
            <Button onClick={open}>menu</Button>
            <Portal>{Drawer}</Portal>
        </Box>
    )
}

const DrawerComponent: FC<{ closeDrawer: () => void }> = (props) => {
    const classes = useStyles({});
    const { NAVS, isLinkActive, id } = useNavigation(0);

    return (
        <Box display='flex' flexDirection='column' alignItems='center'>
            {NAVS.map(nav => (
                <NavLink onClick={() => {
                    props.closeDrawer();
                    nav.onClick?.();
                }} key={nav.id} activeClassName={classes.active} className={clsx(classes.navlink, `${id}-menu-item`)} isActive={isLinkActive(nav.url)} to={nav.url} >
                    {nav.label}
                </NavLink>
            ))}

            <Box my={2} className={`${id}-menu-item`}>
                <Link to="/request-demo" onClick={props.closeDrawer}>
                    <RoundedButton animationDelay={1.5} variant='contained' color='secondary'>GET DEMO</RoundedButton>
                </Link>
            </Box>

        </Box>
    )
}



const useStyles = makeStyles<Theme, any>((theme) => ({
    drawer: {
        minWidth: 200
    },
    active: {
        borderBottom: `2px solid ${theme.palette.primary.main}`,
        color: theme.palette.primary.main + ' !important',
        '&:visited': {
            color: theme.palette.primary.main + ' !important',
        }
    },
    navlink: {
        textDecoration: 'none',
        fontFamily: theme.typography.fontFamily,
        fontWeight: theme.typography.fontWeightMedium,
        fontSize: theme.typography.body2.fontSize,
        color: theme.palette.text.primary,
        margin: theme.spacing(2, 0),
        '&:visited': {
            color: theme.palette.text.primary
        }

    },
}));

export default NavBarMobile;