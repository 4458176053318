import { uniqueId } from "lodash";
import { useContext, useEffect } from "react";
import { NavigatorWheelContext } from "../../Contexts/NavigatorWheelContext";
import * as H from 'history';
import gsap from 'gsap'


export function useNavigation(animationDelay: number = 1.5) {
    const { setCurSection } = useContext(NavigatorWheelContext);
    const id = uniqueId('app-nav-menu');

    useEffect(() => {
        gsap.timeline()
            .fromTo(`.${id}-menu-item`,
                { x: 80, opacity: 0, },
                { x: 0, stagger: 0.2, delay: animationDelay, opacity: 1 }
            )
    }, [])


    const handleNavigation = (to: string) => () => {
        if (to === 'features') {
            setCurSection(0);
        }
    }


    const NAVS = [
        { label: 'HOME', url: '/', id: 'home' },
        { label: 'FEATURES', url: '/features', id: 'features', onClick: handleNavigation('features') },
        { label: 'TEAM', url: '/team', id: 'team' },
        { label: 'BLOG', url: '/blog', id: 'blog' },
    ]



    const isLinkActive = (route: string) => (match: any, location: H.Location) => {
        return route === location.pathname;
    }

    return {
        id,
        isLinkActive,
        NAVS,
    }
}