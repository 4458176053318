import React, { FC, useEffect, createRef, useMemo } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Box, Typography, fade, Divider, Button } from '@material-ui/core';
import gsap from 'gsap';
import clsx from 'clsx';
import { VENZI_WHEEL_LABELS, CIRCULAR_NAV_RADIUS, DISC_NAVIGATOR_SECTION_CLASS, APP_HEADER_HEIGHT, DISC_NAVIGATOR_ID } from '../../Constants';
import { useLocation } from 'react-router-dom';
import { getSectionId } from '../../Screens/Features';

export interface DiscNavigatorProps {
    // radius: number
    // sections?: Array<{ id: string, label: string }>
    onSectionChanged?: (index: number) => void
    currentSection: number
}



const DiscNavigator: FC<DiscNavigatorProps> = (props) => {
    const { currentSection, onSectionChanged = () => { } } = props;
    const radius = CIRCULAR_NAV_RADIUS;
    const sections = VENZI_WHEEL_LABELS;
    const location = useLocation();
    const classes = useStyles(radius);
    const containerRef = createRef<HTMLDivElement>();
    let curRotateAngle = 0;
    const maxDeg = 355;
    const mapIndexToDeg = gsap.utils.mapRange(0, sections.length, 0, maxDeg);
    const mapIndexToDeg2 = gsap.utils.mapRange(0, sections.length, 0, -maxDeg);
    const mapDeltaYToDeg = gsap.utils.mapRange(-30, 30, 10, -10);






    useEffect(() => {
        if (!containerRef.current) return;
        curRotateAngle = (curRotateAngle + mapIndexToDeg2(currentSection)) % 360
        gsap.to(containerRef.current, { rotation: `${curRotateAngle}`, ease: 'easeIn' });
    }, [currentSection])

    const handleClick = (i: number) => () => {
        onSectionChanged(i);
    }


    useEffect(() => {
        gsap.to(containerRef.current, { rotation: `${mapIndexToDeg2(0)}_cw`, ease: 'easeIn' });
    }, [])



    const handleWheel = (e: React.WheelEvent) => {
        if (location.pathname !== '/features') return;

        curRotateAngle = (curRotateAngle + mapDeltaYToDeg(e.deltaY));
        gsap.to(containerRef.current, { rotation: `${curRotateAngle}_cw)`, ease: 'easeIn' })
    }


    const handleMouseEnter = (e: React.MouseEvent<HTMLDivElement>) => {
        const navigatorRef = document.getElementById(DISC_NAVIGATOR_ID);

        if (location.pathname === '/features') {
            // console.log("offset", window.top)
            document.body.style.setProperty('overflow', 'hidden');
        }
    }

    const handleMouseLeave = () => {
        document.body.style.setProperty('overflow', 'auto')
    }



    return (
        <div className={classes.wrapper} onMouseLeave={handleMouseLeave} onMouseEnter={handleMouseEnter} onWheel={handleWheel}>
            <div id='rootCircle' ref={containerRef} className={classes.root} >
                <div id='innerCircle1' className={classes.innerCircle1} />

                <div id='innerCircle2' className={classes.innerCircle2} />
                <div className={classes.labelContainer}>
                    {sections.map((s, i) => (
                        <Box display='flex' onClick={handleClick(i)} alignItems='center' key={s.id} className={clsx(classes.label, DISC_NAVIGATOR_SECTION_CLASS, { [classes.current]: currentSection === i })} style={{ transform: `rotate(${mapIndexToDeg(i)}deg)` }}>
                            <Box width='4px' height='4px' borderRadius='2px' bgcolor="#FFAF00" mr={1} />
                            <Box position='relative'>
                                <Typography
                                    color='textPrimary'
                                    variant="body2"
                                    key={s.id}
                                    className={clsx('sectionLabel', classes.labelText, { [classes.active]: currentSection === i })}

                                >{s.label}</Typography>
                            </Box>
                        </Box>
                    ))}
                </div>
            </div>
        </div>
    );
}

const useStyles = makeStyles<Theme, number, any>((theme) => ({
    wrapper: {
        position: 'relative',
        width: radius => radius * 2,
        height: radius => radius * 2,

    },
    innerCircle1: {
        background: theme.palette.primary.main,
        borderRadius: radius => radius,
        width: radius => radius * 2,
        height: radius => radius * 2,
        top: '-50%',
        transform: 'scale(0.4)',
    },
    innerCircle2: {
        background: theme.palette.common.white,
        borderRadius: radius => radius,
        width: radius => radius * 2,
        height: radius => radius * 2,
        zIndex: 1,
        position: 'absolute',
        top: 0,
        transform: 'scale(0.2)',
    },
    root: {
        background: 'white',
        borderRadius: radius => radius,
        width: radius => radius * 2,
        height: radius => radius * 2,
        // boxShadow: `0px 0px 4px ${fade('#000', 0.3)} !important`
    },
    active: {
        fontWeight: 600,
        color: theme.palette.primary.main,
    },
    labelContainer: {
        // transform: 'translate(-13px)'
    },
    label: {
        transformOrigin: 'center left',
        position: 'absolute',
        cursor: 'pointer',
        width: radius => radius,
        top: `calc(50% - 10px)`,
        left: '50%',
        zIndex: 11,
        paddingLeft: radius => radius / 2.2,
        borderRight: `0px solid ${theme.palette.primary.main}`,
    },
    current: {
        borderRight: `4px solid ${theme.palette.primary.main}`,
        // transitionDelay: 200,
    },
    labelText: {
        '&::after': {
            transition: theme.transitions.create('width'),
            content: '" "',
            position: 'absolute',
            bottom: 0,
            left: 0,
            width: '0%',
            height: 2,
            backgroundColor: theme.palette.primary.main,
        },
        '&:hover': {
            '&::after': {
                width: '100%',
            }
        }
    },

    spokes: {
        transformOrigin: 'left',
        position: 'absolute',
        width: 'calc(50% - 2px)',
        top: '50%',
        left: '50%',
    },

}));

export default DiscNavigator;