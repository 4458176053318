import React, { FC } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Helmet } from "react-helmet";


export interface MetaTagProps {
    title: string
}


const DESCRIPTION_TEXT = 'Execute Virtual, Hybrid or In-Person events';

const MetaTag: FC<MetaTagProps> = (props) => {
    const classes = useStyles();

    return (
        <Helmet>
            <title>Venzi | {props.title}</title>
            <meta property="og:title" content={"Venzi"} />
            <meta property="og:description" content={DESCRIPTION_TEXT} />
            <meta property="og:image" content={process.env.PUBLIC_URL + "/og.png"} />
        </Helmet>
    )
}

const useStyles = makeStyles<Theme, any>((theme) => ({

}));

export default MetaTag;