import React, { FC } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import { GRADIENTS } from '../Theme/themeValues';
import AnimatedBackground from './AnimatedBackground';

export interface AppBackgroundProps {

}

const AppBackground: FC<AppBackgroundProps> = (props) => {
    const classes = useStyles();

    return (
        <Box className={classes.root}>
            <AnimatedBackground />
        </Box>
    )
}

const useStyles = makeStyles<Theme, any>((theme) => ({
    root: {
        position: 'fixed',
        background: GRADIENTS[0],
        zIndex: -1,
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        // [theme.breakpoints.down('sm')]: {
        //     width: '100vw',
        //     height: '100vh',
        //     top: 0,
        //     left: 0
        // }
    }
}));

export default AppBackground;