import React, { FC, lazy, Suspense } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Route, Switch } from 'react-router-dom';
import { Box, LinearProgress } from '@material-ui/core';
import Page404 from '../Screens/Page404';
import lazyImport from '../lazy-import';
import AppBackground from '../Components/AppBackground';
import { APP_HEADER_HEIGHT } from '../Constants';
import classes from '*.module.css';
import Cursor from '../Components/Cursor';

const TermsConditionsScreen = lazyImport('../Screens/TermsConditions');
const PrivacyPolicyScreen = lazyImport('../Screens/PrivacyPolicy');
const Blog = lazyImport('../Screens/Blog');
const Team = lazyImport('../Screens/Team');
const RequestDemo = lazyImport('../Screens/RequestDemo');
const FeaturesScreen = lazyImport('../Screens/Features');
const HomeScreen = lazyImport('../Screens/Home');
const Landing = lazyImport('../Screens/Landing');


const Appnavigation: FC = () => {
    const classes = useStyles();
    return (
        <>
            <Suspense fallback={<AppBackground />}>
                <Switch>
                    <Route path='/' exact component={HomeScreen} />
                    <Route path='/features' exact component={FeaturesScreen} />
                    <Route path='/privacy-policy' exact component={PrivacyPolicyScreen} />
                    <Route path='/terms' exact component={TermsConditionsScreen} />
                    <Route path='/blog' exact component={Blog} />
                    <Route path='/team' exact component={Team} />
                    <Route path='/request-demo' exact component={RequestDemo} />
                    <Route path='/*' exact component={Page404} />
                    <Cursor />
                </Switch>
            </Suspense>

        </>
    )
}

const useStyles = makeStyles<Theme, any>((theme) => ({

}));

export default Appnavigation;

