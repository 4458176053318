import { fade } from '@material-ui/core/styles';



export const THEME_PALETTE = {

    grey: {
        100: '#262626',
        200: '#4D4D4D',
        300: '#808080',
        400: '#D9D9D9',
        500: '#474747',
        600: '#707070',
        700: '#E5E8F2',
        800: '#363535',
        900: '#333333',
    },
    text: {
        primary: "#333333",
        secondary: "#444"
        // primary: "#4D4D4D",
        // secondary: "#808080"
    },
    background: {
        default: "#F5F5F5",
        paper: "#fff"
    },
    common: {
        black: '#000',
        white: '#fff'
    },
    action: {
        active: '#000'
    },
    error: {
        main: '#B71840'
    },
    others: {
        venziYellow: '#FFAF00',
        activeGreen: '#38B468',
        darkBlue: '#02003C',
        gradient: `transparent linear-gradient(320deg, #0073E7 0%, #7426AD 100%) 0% 0% no-repeat padding-box`
    },
    primary: {
        main: '#8525E5',
        contrastText: '#FFFFFF'
    },
    secondary: {
        main: '#FFAF00',
        contrastText: '#FFFFFF'
    },
    success: {
        main: '#4caf50',
    },
    warning: {
        main: '#ff9800',
    },
    info: {
        main: '#2196f3',
    }
}


export const GRADIENTS = [
    `transparent linear-gradient(123deg, #FBFFDB 0%, #CEF0FF 100%) 0% 0% no-repeat padding-box`,
    `transparent linear-gradient(109deg, #2425E5 0%, #4931E5 39%, #8343E5 100%) 0% 0% no-repeat padding-box`
]