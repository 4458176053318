import { Box, Drawer, DrawerProps, IconButton, Toolbar } from '@material-ui/core';
import Typo from './Typo';
import React, { useState } from 'react';
import { CloseOutlined } from '@material-ui/icons';


export interface UseDrawerProps {
    renderChild: (closeDrawer: () => void) => JSX.Element
    title?: string
    PaperProps?: DrawerProps['PaperProps']
}
function useDrawer(props: UseDrawerProps) {
    const { renderChild, title, PaperProps } = props;
    const [open, setOpen] = useState(false);
    const closeDrawer = () => setOpen(false);
    return {
        open: () => setOpen(true),
        close: closeDrawer,
        UiComponent: (
            <Drawer PaperProps={PaperProps} anchor='right' open={open} onClose={closeDrawer}>
                <Toolbar>
                    <Box flex={1} />
                    <IconButton onClick={closeDrawer}><CloseOutlined /></IconButton>
                </Toolbar>
                {renderChild(closeDrawer)}
            </Drawer>
        )
    }
}

export default useDrawer