import React, { FC, useContext, createRef, useEffect, useState } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
import DiscNavigator from "../../Components/DiscNavigator";
import gsap, { TimelineMax } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { NavigatorWheelContext } from "../../Contexts/NavigatorWheelContext";
import { GRADIENTS } from "../../Theme/themeValues";
import { useHistory } from "react-router-dom";
import { APP_HEADER_HEIGHT, CIRCULAR_NAV_RADIUS, FEATURES_SCREEN_ROOT_ID, VENZI_WHEEL_LABELS } from "../../Constants";
import Typo from "../../Components/Typo";
import { uniqueId, debounce } from "lodash";
import Section from "../../Components/Section";
import ReactScrollDetect, { DetectSection } from "react-scroll-detect";
import withMountAnimation from "../withMountAnimation";
import VENZI_CONFIG from "../../Constants/venzi.json";
import Lottie from "react-lottie";
import StyledLabel from "../../Components/StyledLabel";
import Carousel from "../../Components/Carousel";
import MetaTag from "../../Components/MetaTag";
import { useMediaQuery } from "../../Hooks/useMediaQuery";
import DiscMobile from "./DiscMobile";
export interface FeaturesScreenProps {}

const getAssetUrl = (asset: string) => {
    try {
        return require("../../Assets/screenFeatures/" + asset);
    } catch (error) {
        return "";
    }
};
export const getSectionId = (i: number) => `section-${i}`;

const FeaturesScreen: FC<FeaturesScreenProps> = (props) => {
    const classes = useStyles();
    const { curSection, setCurSection, navigatorAnimation } = useContext(NavigatorWheelContext);
    const discContainerRef = createRef<HTMLDivElement>();
    const history = useHistory();
    const { isDeviceSm } = useMediaQuery();
    const [prevIndex, setprevIndex] = useState(0);

    const duration = gsap.utils.mapRange(0, VENZI_WHEEL_LABELS.length - 1, 0.3, 2.5);
    let prev = 0;

    useEffect(() => {
        handleSectionChange(curSection);
    }, []);

    const handleSectionChange = (i: number) => {
        // gsap.to(window, {
        //     scrollTo: {
        //         y: `#${getSectionId(i)}`,
        //         offsetY: APP_HEADER_HEIGHT,
        //     },
        //     // delay: 1,
        //     duration: duration(Math.abs(prevIndex - i)),
        //     ease: "power2.easeOut"
        // })
        // setprevIndex(i);
    };

    const handleSectionEnter = (i: number) => {
        setCurSection(i);
        // console.log("tween", instance);
    };

    return (
        <Box id={FEATURES_SCREEN_ROOT_ID} className={classes.root}>
            {isDeviceSm ? <DiscMobile /> : null}
            <MetaTag title="Features" />
            <Box className={classes.leftSection} />
            <Box className={classes.container} ml={isDeviceSm ? 0 : `${CIRCULAR_NAV_RADIUS}px`}>
                <ReactScrollDetect triggerPoint={"top"} onChange={handleSectionEnter}>
                    {VENZI_CONFIG.filter((c) => !c.parked).map((config, i) => (
                        <DetectSection key={i}>
                            <Box
                                id={getSectionId(i)}
                                className={classes.section}
                                minHeight={isDeviceSm ? 0 : "800px"}
                                width="100%"
                            >
                                <Box position="relative" maxWidth="980px" mx="auto" px={5} pt={10}>
                                    <StyledLabel
                                        gutterBottom
                                        weight="medium"
                                        variant="h3"
                                        text={config["Section Title"]}
                                    />
                                    {/* <Typo gutterBottom variant='h3' weight='medium'>{config['Section Title']}</Typo> */}
                                    <Typo variant="h6">{config["Description"]}</Typo>

                                    {config["image"] ? (
                                        <Box my={4} width="100%">
                                            <img src={getAssetUrl(config["image"])} width="100%" />
                                        </Box>
                                    ) : null}

                                    {/* {config['image2'] ? (
                                        <Box my={4} width="100%" >
                                            <img src={getAssetUrl(config['image2'])} width="100%" />
                                        </Box>
                                    ) : null} */}

                                    {/* {config['images'] ? (
                                        <Box my={4} width="100%" >
                                            <Carousel images={config['images'].map(i => getAssetUrl(i))} />
                                        </Box>
                                    ) : null} */}

                                    {config["lottie"] ? (
                                        <Box my={4} width="100%">
                                            <Lottie
                                                options={{
                                                    autoplay: false,
                                                    loop: true,
                                                    animationData: getAssetUrl(config["lottie"]),
                                                }}
                                            />
                                        </Box>
                                    ) : null}

                                    {config["video"] ? (
                                        <Box my={4} width="100%">
                                            <video width="100%" height="100%" loop autoPlay muted>
                                                <source src={getAssetUrl(config["video"])} type="video/mp4" />
                                                Your browser does not support the video tag.
                                            </video>
                                        </Box>
                                    ) : null}
                                </Box>
                            </Box>
                        </DetectSection>
                    ))}
                </ReactScrollDetect>
            </Box>
        </Box>
    );
};

const useStyles = makeStyles<Theme, any>((theme) => ({
    root: {
        // background: GRADIENTS[0],
    },
    leftSection: {
        width: CIRCULAR_NAV_RADIUS,
        height: "100%",
        overflow: "hidden",
        [theme.breakpoints.down("sm")]: {
            dispplay: "none",
        },
    },
    container: {},
    section: {},
}));

export default withMountAnimation(FeaturesScreen);
