import React, { FC, useEffect, createRef } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Box, BoxProps } from '@material-ui/core';
import gsap from 'gsap';
import { debounce, throttle } from 'lodash';
import { THEME_PALETTE } from '../Theme/themeValues';
import Typo, { TypoProps } from './Typo';
import { uniqueId } from 'lodash';


export interface StyledLabelProps extends Omit<TypoProps, 'className'> {
    text: string
    containerClass?: BoxProps['className']
}

const StyledLabel: FC<StyledLabelProps> = (props) => {
    const { children, text, containerClass, ...typoProps } = props;
    const classes = useStyles();
    const id = uniqueId('-styled-label')
    const tl = gsap.timeline();



    return (
        <Box id={id} className={containerClass} position='relative'>
            <Typo variant='h4' {...typoProps} style={{ opacity: 0 }}>{text}</Typo>
            <Typo variant='h4' {...typoProps} className={classes.label}>{text}</Typo>
            <Typo variant='h4' {...typoProps} className={classes.labelBg}>{text}</Typo>
        </Box>
    )
}

const useStyles = makeStyles<Theme, any>((theme) => ({
    label: {
        zIndex: 2,
        position: 'absolute',
        top: 0,
        left: 0
    },
    labelBg: {
        position: 'absolute',
        top: 1,
        left: 1,
        zIndex: 1,
        color: THEME_PALETTE.others.venziYellow
    }
}));

export default StyledLabel;