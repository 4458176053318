import { createMuiTheme, Theme } from '@material-ui/core/styles';
import shadows from '@material-ui/core/styles/shadows';
import { THEME_PALETTE } from './themeValues';

const { others: otherColors, ...palette } = THEME_PALETTE;


export const getGradientFromTheme = (theme: Theme) => {
    const primaryColor = '#0073E7';
    const secondaryColor = '#7426AD';
    return `transparent linear-gradient(320deg, ${primaryColor} 0%, ${secondaryColor} 100%) 0% 0% no-repeat padding-box`
}



shadows[1] = '0px 1px 1px #00000029';
shadows[2] = '0px 3px 6px #00000029';
shadows[3] = '-3px 0px 6px #00000029';

const sm = createMuiTheme().breakpoints.down('sm');

export default createMuiTheme({
    shadows,
    palette,
    typography: {
        fontFamily: 'Proxima Nova',
        fontWeightBold: 500,
        fontWeightMedium: 500,
        fontWeightRegular: 400,
        h1: {
            fontSize: 78
        },
        h2: {
            fontSize: 54,
            letterSpacing: 0.6,
            lineHeight: 1.2,
            [sm]: {
                fontSize: 28
            }
        },
        h3: {
            fontSize: 48,
            [sm]: {
                fontSize: 24
            }
        },
        h4: {
            fontSize: 34,
            [sm]: {
                fontSize: 20
            }
        },
        h5: {
            fontSize: 24,
            letterSpacing: 0.24,
            [sm]: {
                fontSize: 18
            }
        },
        h6: {
            fontSize: 20,
            [sm]: {
                fontSize: 16
            }
        },
        subtitle1: {
            fontSize: 16,
            [sm]: {
                fontSize: 14
            }
        },
        subtitle2: {
            fontSize: 14,
            [sm]: {
                fontSize: 12
            }
        },
        body1: {
            fontSize: 16,
            [sm]: {
                fontSize: 14
            }
        },
        body2: {
            fontSize: 14,
            [sm]: {
                fontSize: 12
            }
        },
        caption: {
            fontSize: 12,
            [sm]: {
                fontSize: 10
            }
        },
        overline: {
            fontSize: 10,
            [sm]: {
                fontSize: 8
            }
        },

    },
    overrides: {
        MuiButton: {
            contained: {
                '&:hover': {
                    boxShadow: 'none'
                }
            }
        }
    }
})