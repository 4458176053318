import React, { FC, useContext, createRef, useEffect, useState, useMemo } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import DiscNavigator from '../../Components/DiscNavigator';
import { NavigatorWheelContext } from '../../Contexts/NavigatorWheelContext';
import gsap from 'gsap';
import { DISC_NAVIGATOR_ID, CIRCULAR_NAV_RADIUS, APP_HEADER_HEIGHT, VENZI_WHEEL_LABELS } from '../../Constants';
import { useLocation, useHistory } from 'react-router-dom';
import { getSectionId } from '../../Screens/Features';
import { MOUNT_ANIMATION_DURATION } from '../../Screens/withMountAnimation';
import { useMediaQuery } from '../../Hooks/useMediaQuery';


export interface GhostWheelProps {

}


type Location = 'home' | 'features' | 'blog';

const GhostWheel: FC<GhostWheelProps> = (props) => {
    const classes = useStyles();
    const { curSection, setCurSection, navigatorAnimation } = useContext(NavigatorWheelContext);
    const containerRef = createRef<HTMLDivElement>();
    const location = useLocation();
    const { isDeviceSm } = useMediaQuery();
    const history = useHistory();
    const [prevIndex, setprevIndex] = useState(0);
    const [mounted, setMounted] = useState(false)

    const animationState: Location = useMemo(() => {

        if (location.pathname === '/') return 'home'
        else if (location.pathname === '/blog') return 'blog'

        return 'features';
    }, [location]);




    const duration = gsap.utils.mapRange(0, VENZI_WHEEL_LABELS.length - 1, 0.3, 2.5)

    useEffect(() => {
        parseLocation();
    }, [])

    useEffect(() => {
        if (location.pathname === '/features') {
            history.replace('/features#' + getSectionId(curSection))
            !mounted && scrollWindow(curSection);
        }
    }, [curSection])



    const parseLocation = () => {
        if (location.pathname !== '/features') return;
        const _x = location.hash.split('-');
        if (_x.length === 2) {
            const section = parseInt(_x[1]);
            if (section) {
                handleSectionChange(section)
            }
        }
    }

    const handleSectionChange = (index: number) => {
        switch (animationState) {
            case 'home':
                navigatorAnimation.homeExit();
                setTimeout(() => {
                    setCurSection(index);
                    scrollWindow(index, true);
                    // history.push('/features');
                }, 300);
                history.replace('/features#' + getSectionId(index))
                break;
            case 'features': {
                setCurSection(index);
                scrollWindow(index);
                break;
            }


        }
        setprevIndex(index);
    }


    const scrollWindow = (index: number, delayScroll: boolean = false) => {
        setTimeout(() => {
            gsap.to(window, {
                scrollTo: {
                    y: `#${getSectionId(index)}`,
                    offsetY: APP_HEADER_HEIGHT,
                },
                // delay: 1,
                duration: duration(Math.abs(prevIndex - index)),
                ease: "power2.easeOut"
            })
            setMounted(true)
        }, delayScroll || !mounted ? MOUNT_ANIMATION_DURATION + 200 : 0);

    }


    return isDeviceSm ? null : (
        <div ref={containerRef} id={DISC_NAVIGATOR_ID} style={{ position: animationState === 'home' ? 'absolute' : 'fixed' }} className={classes.root}>
            <DiscNavigator
                currentSection={curSection}
                onSectionChanged={handleSectionChange}
            />
        </div>
    )
}

const useStyles = makeStyles<Theme, any>((theme) => ({
    root: {
        position: 'absolute',
        // opacity: 0,
        display: 'none',
        zIndex: theme.zIndex.appBar - 1

    }
}));

export default GhostWheel;