import React from 'react';
import AppToastProvider, { AppToastContext } from './AppToastContext';
import NavigatorWheelContextProvider from './NavigatorWheelContext';


const ContextProviders: React.FC = (props) => {

    return (
        <AppToastProvider>
            <NavigatorWheelContextProvider>
                {props.children}
            </NavigatorWheelContextProvider>
        </AppToastProvider>
    )
}

export default ContextProviders;