import React, { FC, useEffect, useRef } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import gsap from 'gsap'
export interface CursorProps {

}

// var cursor = $(".cursor"),
//     follower = $(".cursor-follower");

// var posX = 0,
//     posY = 0;

// var mouseX = 0,
//     mouseY = 0;

// TweenMax.to({}, 0.016, {
//     repeat: -1,
//     onRepeat: function () {
//         posX += (mouseX - posX) / 9;
//         posY += (mouseY - posY) / 9;

//         TweenMax.set(follower, {
//             css: {
//                 left: posX - 12,
//                 top: posY - 12
//             }
//         });

//         TweenMax.set(cursor, {
//             css: {
//                 left: mouseX,
//                 top: mouseY
//             }
//         });
//     }
// });

// $(document).on("mousemove", function (e) {
//     mouseX = e.clientX;
//     mouseY = e.clientY;
// });
// // yellow circle
// $(".link").on("mouseenter", function () {
//     cursor.addClass("active");
//     follower.addClass("active");
// });
// $(".link").on("mouseleave", function () {
//     cursor.removeClass("active");
//     follower.removeClass("active");
// });

const Cursor: FC<CursorProps> = (props) => {
    const classes = useStyles({});
    const posX = useRef(0);
    const posY = useRef(0);
    const mouseX = useRef(0);
    const mouseY = useRef(0);

    const follower = useRef<HTMLDivElement | null>(null);
    const cursor = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        console.log("mounted")
        gsap.to({}, 0.016, {
            repeat: -1,
            onRepeat: function () {
                posX.current += (mouseX.current - posX.current) / 9;
                posY.current += (mouseY.current - posY.current) / 9;

                gsap.set(follower.current, {
                    css: {
                        left: posX.current - 20,
                        top: posY.current - 20
                    }
                });

                // gsap.set(cursor.current, {
                //     css: {
                //         left: mouseX.current,
                //         top: mouseY.current
                //     }
                // });
            }
        });

        const root = document.getElementById('root');
        if (root) {
            root.addEventListener('mousemove', (e) => {
                mouseX.current = e.x;
                mouseY.current = e.y;
            })
            console.log(mouseX.current, mouseY.current)
        }
    }, [])

    return (
        <Box>
            <div ref={follower} className={classes.follower} />
            {/* <div ref={cursor} className={classes.cursor} /> */}
        </Box>
    )
}

const useStyles = makeStyles<Theme, any>((theme) => ({
    cursor: {
        position: 'fixed',
        backgroundColor: 'black',
        width: 10,
        height: 10,
        borderRadius: '100 %',
        transition: `0.3s cubic - bezier(0.75, -1.27, 0.3, 2.33) transform,
    0.2s cubic - bezier(0.75, -0.27, 0.3, 1.33) opacity`,
        userSelect: 'none',
        pointerEvents: 'none',
        zIndex: 10000,
        transform: `scale(1) translate(5px, 5px)`,
        '&.active': {
            opacity: 1,
            transform: `scale(0)`,
        }


    },
    follower: {
        backdropFilter: 'hue-rotate(90deg)',
        position: 'fixed',
        // backgroundColor: 'black',
        border: `2px solid black`,
        width: 50,
        height: 50,
        borderRadius: '50%',
        transition: `0.3s cubic - bezier(0.75, -1.27, 0.3, 2.33) transform,
    0.2s cubic - bezier(0.75, -0.27, 0.3, 1.33) opacity`,
        userSelect: 'none',
        pointerEvents: 'none',
        zIndex: 10000,

        transform: `scale(1)`,

        '&.active': {
            opacity: 1,
            transform: `scale(0)`,
        }


    }
}));

export default Cursor;