import React, { FC, useContext, useEffect, useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Box, CircularProgress } from '@material-ui/core';
import { uniqueId } from 'lodash';
import gsap from 'gsap';
import { useHistory, useLocation } from 'react-router-dom';
import Spinner from '../Components/Spinner';
import { NavigatorWheelContext } from '../Contexts/NavigatorWheelContext';
import { REVEAL_TRIGGER_CLASS } from './Home/Sections/VenziWheel';


export interface WithMountAnimation {

}



export const MOUNT_ANIMATION_DURATION = 820;
/**
 * HOC
 * 
 */
const id = uniqueId('withAnimationHOC');
function withMountAnimation<P>(C: React.FC<P & WithMountAnimation>) {


    return (props: P) => {
        const [mounted, setMounted] = useState(false);
        const { navigatorAnimation } = useContext(NavigatorWheelContext);
        const { pathname } = useLocation()
        const history = useHistory();

        useEffect(() => {
            if (mounted) {
                gsap.to(`#${id}`, { opacity: 1, duration: 1, delay: 0.1 })

            }
        }, [mounted])




        useEffect(() => {
            setTimeout(() => {
                setMounted(true);
            }, MOUNT_ANIMATION_DURATION);

            if (pathname === '/features') {
                setTimeout(() => {
                    navigatorAnimation.showDisc()
                    navigatorAnimation.featuresEntry()
                }, 1000);
            }
            if (pathname === '/') {
                // if (navigatorAnimation.prevScreen === '/features')
                navigatorAnimation.featureExit()
                // navigatorAnimation.homeEntry(`.${REVEAL_TRIGGER_CLASS}`)
            }
            if (pathname === '/request-demo') {
                // if (navigatorAnimation.prevScreen === '/features')
                navigatorAnimation.featureExit()
                // navigatorAnimation.homeEntry(`.${REVEAL_TRIGGER_CLASS}`)
            }
            if (pathname === '/blog') {
                navigatorAnimation.featureExit();
            }
            
            if (pathname === '/team') {
                navigatorAnimation.featureExit();
            }
            window.scrollTo({ top: 0 })

        }, [])

        return mounted ? (
            <div id={id} style={{ opacity: 0 }}>
                <C {...props} />
            </div>
        ) : (
            <Spinner fullScreen />
        )
    }
}



const useStyles = makeStyles<Theme, any>((theme) => ({

}));

export default withMountAnimation;