import React, { useEffect } from 'react';
import { Theme, ButtonProps, Button } from '@material-ui/core';
import { createStyles, makeStyles, darken } from '@material-ui/core/styles';
import clsx from 'clsx';
import gsap from 'gsap';

interface RoundedButtonProps extends Omit<ButtonProps, 'className'> {
    size?: 'medium' | 'large',
    animationDelay?: number
    borderColor?: string
}

const RoundedButton: React.FC<RoundedButtonProps> = (props) => {
    const { size, animationDelay = 0, borderColor, ...buttonProps } = props;
    const classes = useStyles(borderColor)()

    useEffect(() => {
        // gsap.to(`.${classes.root}`, {
        //     delay: animationDelay,
        //     borderRadius: 30,
        //     duration: 0.4
        // })
    }, [])

    return (
        <Button className={clsx(classes.root, {
            [classes.sizeMedium]: size === 'medium',
            [classes.sizeLarge]: size === 'large',
            [classes.containedPrimary]: props.variant === 'contained' && props.color === 'primary',
            [classes.containedSecondary]: props.variant === 'contained' && props.color === 'secondary',
            [classes.border]: !!borderColor
        })} {...buttonProps}>
            {props.children}
        </Button>
    )
}

const useStyles = (borderColor?: string) => makeStyles((theme: Theme) => createStyles({
    root: {
        borderRadius: 30,
    },
    containedPrimary: {
        '&:hover': {
            backgroundColor: darken(theme.palette.primary.main, 0.3)
        }
    },
    containedSecondary: {
        '&:hover': {
            backgroundColor: darken(theme.palette.secondary.main, 0.3)
        }
    },
    sizeMedium: {
        height: 40
    },
    border: {
        border: `1px solid ${borderColor}`
    },
    sizeLarge: {
        height: 50,
        paddingLeft: 26,
        paddingRight: 26
    },
}))

export default RoundedButton