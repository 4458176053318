import gsap from 'gsap';
import { DISC_NAVIGATOR_ID, CIRCULAR_NAV_RADIUS, APP_HEADER_HEIGHT, HOME_SCREEN_ROOT_ID, DISC_NAVIGATOR_SECTION_CLASS, CIRCULAR_NAV_POSB } from '../Constants';
import { NavigatorWheelPosition } from '../Contexts/NavigatorWheelContext';
import { fade } from '@material-ui/core';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { Elastic } from 'gsap';

export type DiscNavigatorAnimationStatus = '';
export type TScreen = '/' | '/features';


const INNER_CIRCLE_SCALE1 = 0.35;
const INNER_CIRCLE_SCALE2 = 0.15;
const TEXT_CONTAINER_PADDING = CIRCULAR_NAV_RADIUS / 2.4;

export class DiscNavigatorAnimation {
    private idSelector: string = `#${DISC_NAVIGATOR_ID}`;
    private rootCircle = `#rootCircle`;
    private innerCircle1 = `#innerCircle1`;
    private innerCircle2 = `#innerCircle2`;
    private discSectionLabel = '.sectionLabel';
    private homeScreen = `#${HOME_SCREEN_ROOT_ID}`;
    private discSection = `.${DISC_NAVIGATOR_SECTION_CLASS}`;
    private homeDiscTriggerPoint = '#trigger-home-disc';
    private intermediatePos: NavigatorWheelPosition = { y: undefined, x: -1000 };
    // private intermediatePos: NavigatorWheelPosition = { y: APP_HEADER_HEIGHT * 3, x: window.innerWidth / 2 - CIRCULAR_NAV_RADIUS };
    posB: NavigatorWheelPosition = { x: -CIRCULAR_NAV_RADIUS, y: CIRCULAR_NAV_POSB.y };
    posA: NavigatorWheelPosition | undefined = undefined;

    private homeEntryAnimation: gsap.core.Timeline | null = null;
    prevScreen: TScreen = '/';

    DiscNavigatorAnimation() {

    }



    setPosA(pos: NavigatorWheelPosition) {
        this.posA = pos;
    }







    cleanupHomeEntry() {
        console.log("cleaniniguo", this.homeEntryAnimation)
        this.homeEntryAnimation?.clear();
        this.homeEntryAnimation = null;
    }

    homeEntry(trigger: string) {

        if (!this.posA) return;
        this.setToShrinkedState();
        this.homeEntryAnimation = gsap.timeline({ scrollTrigger: { trigger, toggleActions: 'restart pause' } })
            .fromTo(this.idSelector, { display: 'block', y: (this.posA.y || 2000) + 500, x: this.posA.x }, {
                y: this.posA.y,
                duration: 1,
                onComplete: () => this.prevScreen = '/'
            })
            .add(this.staggerAnimation(true));

    }






    showDisc() {
        gsap.set(this.idSelector, { display: 'block' });
    }
    hideDisc() {
        gsap.set(this.idSelector, { display: 'none' })
    }

    homeExit() {
        // this.staggerAnimation(true)
        return gsap
            .to(this.idSelector, { x: -window.innerWidth * 2, duration: 0.3, ease: 'easeIn' })
    }

    featureExit() {
        gsap.timeline()
            .add(this.staggerAnimation())
            .to(this.idSelector, { x: -window.innerWidth * 2 })
    }

    featuresEntry(trigger?: string) {
        this.setToShrinkedState();
        return gsap.timeline(trigger ? { scrollTrigger: { trigger, toggleActions: 'restart pause', } } : undefined)
            .fromTo(this.idSelector, { y: this.posB.y, x: -2000 }, {
                x: this.posB.x,
                y: this.posB.y,
                duration: 1,
                onComplete: () => console.log("features entry", this.posB)
            })
            .add(this.staggerAnimation(true))

    }



    setToShrinkedState() {
        gsap.set(this.rootCircle, {
            background: 'transparent',
            boxShadow: 'none',
        })
        gsap.set(this.discSectionLabel, {
            opacity: 0,
        })
        gsap.set(this.innerCircle1, {
            scale: 0,
        })
        gsap.set(this.discSection, {
            stagger: 0.03,
            paddingLeft: 20,
            opacity: 0,
        })
    }

    setToExpandedState() {
        gsap.set(this.innerCircle2, {
            scale: 1
        })
        gsap.set(this.rootCircle, {
            background: 'white',
            boxShadow: `0px 0px 4px ${fade('#000', 0.3)}`
        })
        gsap.set(this.innerCircle2, {
            scale: INNER_CIRCLE_SCALE2,
            // scale: 0.2,
            duration: 0.2
        })
        gsap.set(this.discSectionLabel, {
            opacity: 1,
            duration: 0.3
        })
        gsap.set(this.innerCircle1, {
            scale: INNER_CIRCLE_SCALE2,
            // scale: 0.4,
            duration: 0.4
        })
        gsap.set(this.discSection, {
            stagger: 0.02,
            opacity: 1,
            paddingLeft: TEXT_CONTAINER_PADDING,
        })
    }

    staggerAnimation(reverse = false, timelineConfig?: gsap.TimelineVars) {

        if (reverse) {

            return gsap.timeline(timelineConfig)
                .to(this.innerCircle2, {
                    scale: 1,
                })
                .to(this.rootCircle, {
                    background: 'white',
                    boxShadow: `0px 0px 4px ${fade('#000', 0.3)}`
                })
                .to(this.innerCircle2, {
                    scale: INNER_CIRCLE_SCALE2,
                    duration: 0.2
                })
                .to(this.discSectionLabel, {
                    opacity: 1,
                    duration: 0.2
                })
                .to(this.innerCircle1, {
                    scale: INNER_CIRCLE_SCALE1,
                    duration: 0.3
                })
                .to(this.discSection, {
                    stagger: 0.01,
                    opacity: 1,
                    paddingLeft: TEXT_CONTAINER_PADDING,
                }, '-=1')
        } else {
            return gsap.timeline()
                .to(this.rootCircle, {
                    background: 'transparent',
                    boxShadow: 'none',
                })
                .to(this.discSectionLabel, {
                    opacity: 0,
                    duration: 0.2
                }, '-=1')
                .to(this.innerCircle1, {
                    scale: 0,
                    duration: 0.3
                })
                .to(this.discSection, {
                    stagger: 0.01,
                    paddingLeft: 20,
                    opacity: 0,
                }, '-=1')
        }
    }
}