import { Box } from '@material-ui/core';
import React, { FC } from 'react';
import Lottie from 'react-lottie';
import './style.css';
import LOTTIE_JSON from '../../Assets/lottie/loader.json';

export interface SpinnerProps {
    fullScreen?: boolean
}



const Spinner: FC<SpinnerProps> = (props) => {
    const { fullScreen = false } = props;
    return (
        <Box width={fullScreen ? '100vw' : undefined} height={fullScreen ? '80vh' : 0} display='flex' alignItems='center' justifyContent='center'>
            {/* <div className="spinner">
                <div className="cube1"></div>
                <div className="cube2"></div>
            </div> */}
            <Box width={50} height={50}>
                <Lottie options={{
                    autoplay: true,
                    loop: true,
                    animationData: LOTTIE_JSON
                }} />
            </Box>
        </Box>
    )
}


export default Spinner;