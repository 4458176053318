import venziConfig from './venzi.json';
import { NavigatorWheelPosition } from '../Contexts/NavigatorWheelContext';

export const VENZI_WHEEL_LABELS = venziConfig.filter(c => !c.parked).map(c => ({ id: c["Section Title"], label: c["Section Title"] }));

export const APP_HEADER_HEIGHT = 64;

export const CIRCULAR_NAV_RADIUS = (window.innerHeight - APP_HEADER_HEIGHT - 140) / 2;

export const CIRCULAR_NAV_POSB: NavigatorWheelPosition = { y: APP_HEADER_HEIGHT + 70, x: -CIRCULAR_NAV_RADIUS }

export const DISC_NAVIGATOR_ID = 'disc-navigator';
export const HOME_SCREEN_ROOT_ID = 'home-container';
export const FEATURES_SCREEN_ROOT_ID = 'features';
export const DISC_NAVIGATOR_SECTION_CLASS = 'disc-section-label'