import React, { Component, FC, useState } from 'react';
import { SnackbarProps, Snackbar, SnackbarContent, Box, SvgIconTypeMap } from '@material-ui/core';
import { ErrorOutline, CheckCircleOutline } from '@material-ui/icons';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';
// import { ToastProps, ToastMessage } from 'mui-wrapper-utils';


export type TToastVariant = 'success' | 'error';

interface ContextProps {
    open: boolean,
    message: string,
    variant: TToastVariant,
    showToast: (message: string, options?: Partial<IToastContext>) => void,
    hideToast: () => void
}

const VARIANT_BACKGROUND: Record<TToastVariant, string> = {
    error: '#e00f0f',
    success: '#65C91C'
}
const VARIANT_ICON: Record<TToastVariant, JSX.Element> = {
    error: <ErrorOutline />,
    success: <CheckCircleOutline />
}

export const AppToastContext = React.createContext<ContextProps>({
    open: false,
    message: '',
    variant: 'success',
    showToast: (message: string, options?: Partial<IToastContext>) => { },
    hideToast: () => { }
});

export interface IToastContext extends SnackbarProps {
    message?: string,
    variant?: TToastVariant
}

export const useAppToast = (initialState: IToastContext) => {
    const [toastState, setToastState] = useState({ open: false, message: '', ...initialState });

    const showToast = (message: string, options?: Partial<IToastContext>) => {
        setToastState({
            ...toastState,
            message,
            open: true,
            ...options
        })
    };
    const hideToast = () => {
        setToastState(prev => ({
            ...prev,
            open: false,
            // message: ''
        }))
    }
    return { showToast, hideToast, message: toastState.message || '', open: toastState.open, variant: toastState.variant || 'success' };
}

const AppToastProvider: FC = (props) => {
    const { showToast, hideToast, message, open, variant } = useAppToast({ open: false });
    return (
        <AppToastContext.Provider value={{
            message,
            open,
            showToast,
            hideToast,
            variant
        }}>
            {props.children}
            <Snackbar open={open}
                ContentProps={{ style: { backgroundColor: VARIANT_BACKGROUND[variant], fontSize: '16px', fontWeight: 700, textAlign: 'center' } }}
                message={message} autoHideDuration={10000} onClose={hideToast}
            />

        </AppToastContext.Provider>
    )
}

export default AppToastProvider;