import React, { createContext, useEffect, useState } from 'react';
import { CIRCULAR_NAV_RADIUS, APP_HEADER_HEIGHT, CIRCULAR_NAV_POSB } from '../Constants';
import { DiscNavigatorAnimation } from '../Animation/discNavigator';
import { useLocation } from 'react-router-dom';

export interface NavigatorWheelPosition {
    x: number | undefined
    y: number | undefined
}

export interface AnimationStatus {
    animating: boolean,
    from: NavigatorWheelPosition
    to: NavigatorWheelPosition
}
export const NavigatorWheelContext = createContext<NavigatorWheelContextProviderValue>({
    curSection: 0,
    setCurSection: () => { },
    navigatorAnimation: new DiscNavigatorAnimation()
});

const NavigatorWheelContextProvider: React.FC = (props) => {
    const [curSection, setCurSection] = useState(0);
    const location = useLocation();





    const providerValue: NavigatorWheelContextProviderValue = {
        navigatorAnimation: new DiscNavigatorAnimation(),
        curSection,
        setCurSection,
    }

    useEffect(() => {
        // parseLocation();
    }, [])


    const parseLocation = () => {
        if (location.pathname !== '/features') return;
        const _x = location.hash.split('-');
        if (_x.length === 2) {
            const section = parseInt(_x[1]);
            if (section) setCurSection(section)
        }
    }

    return (
        <NavigatorWheelContext.Provider value={providerValue}>
            {props.children}
        </NavigatorWheelContext.Provider>
    )
}


export interface NavigatorWheelContextProviderValue {
    curSection: number
    setCurSection: React.Dispatch<React.SetStateAction<number>>
    navigatorAnimation: DiscNavigatorAnimation
}

export default NavigatorWheelContextProvider;
