import React, { FC, useContext, useEffect } from 'react';
import AppNavigation from '../Routes/AppNavigation';
import AppHeader from '../Features/AppHeader';
import GhostWheel from '../Features/GhostWheel';
import AppBackground from '../Components/AppBackground';
import { NavigatorWheelContext } from '../Contexts/NavigatorWheelContext';
import { useHistory, useLocation } from 'react-router-dom';
import Cursor from '../Components/Cursor';



const RootLayout: FC = () => {
    const history = useHistory();

    useEffect(() => {
        // history.replace('/features');
    }, [])

    return (
        <>
            <GhostWheel />
            <AppBackground />
            <AppHeader />
            <AppNavigation />
            {/* <Cursor /> */}
        </>
    )
}



export default RootLayout;

